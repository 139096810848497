.elc-product-full {
  .elc-vto-foundation {
    &-shade-grid {
      .elc-vto {
        &-custom-shade-picker-item-name {
          width: 65px;
        }
        &-tab {
          margin-bottom: 8px;
          padding: 12px 0;
        }
        &-perfect-shade-label {
          display: flex;
          font-size: 13px;
          justify-content: center;
          white-space: nowrap;
          width: 64px;
        }
        &-shade-info-right-container {
          width: 37%;
        }
        &-see-pair-product {
          border: 0;
          text-align: $rdirection;
          text-decoration: underline;
        }
        &-see-all-shades,
        &-see-perfect-shades {
          &.elc-button {
            [data-component] & {
              border: 0;
              text-decoration: underline;
            }
          }
        }
      }
    }
    .elc-product-cta-wrapper {
      .elc-product-notify-me-button {
        font-size: 14px;
        height: 48px;
        line-height: normal;
      }
    }
  }
}

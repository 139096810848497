#ot-sdk-btn {
  &.ot-sdk-show-settings-btn {
    background: none !important;
    border: none !important;
    padding: 0 !important;
    color: $color-cl-green-2 !important;
    font-family: $base-bold-font-family;
    &:hover {
      color: $color-green-dark !important;
    }
  }
  &.ot-sdk-show-settings-btn-en {
    background: none !important;
    border: none !important;
    padding: 0 !important;
    color: $color-cl-green-2 !important;
    font-family: $base-bold-font-family;
    &:hover {
      color: $color-green-dark !important;
    }
  }
  &.ot-sdk-show-settings-btn-my {
    background: none !important;
    border: none !important;
    padding: 0 !important;
    color: $color-cl-green-2 !important;
    font-family: $base-bold-font-family;
    &:hover {
      color: $color-green-dark !important;
    }
  }
}

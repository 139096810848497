$color-error-message: #ef6ea8;

.appt-book {
  .appointment-select {
    .appointments-container {
      .sign-in-container {
        .sign-in-form-container {
          .social-login__email-opt-in {
            #facebook__email-opt-in-register-panel {
              height: 17px;
              width: 25px;
            }
            .social-login__opt-in-label {
              margin-top: 0px !important;
              padding-left: 3px;
            }
          }
        }
      }
      .book-appt-container {
        .registration__terms-text,
        .registration__sms-list-text,
        .registration__email-list-text {
          padding-left: 3px;
        }
        .mobile-phone-fieldset {
          .appt-book-mobile-prefix {
            display: none;
          }
          .appt-book-mobile {
            float: right;
            width: 100%;
          }
        }
        .error,
        & .error a {
          color: $color-error-message;
        }
      }
    }
  }
  .appt-book-page-header {
    &-bg {
      height: 210px !important;
    }
    .appt-book-page-header-content {
      height: 80% !important;
      &__subhead {
        width: 68% !important;
      }
    }
  }
}

#appointments {
  .appointments-page {
    .appt-book-page-header-content {
      &__title {
        h2 {
          margin-top: 70px;
          font-weight: bold;
        }
        h4 {
          font-weight: bold;
        }
      }
    }
  }
  .appt-book-overlay {
    .overlay-content-container {
      height: 280px !important;
    }
  }
}

.store-locator-mobile-v1 {
  .gm-style-iw-d {
    padding-bottom: 30px;
  }
  .doors_results {
    .store-locator_book-appointment {
      p {
        margin: 0.5em 0;
      }
    }
  }
}

#appt-book-btn {
  text-align: center;
}

.page-node-293986 {
  .inner {
    padding-top: 20%;
  }
}

@if $password_strength {
  .device-mobile #foreground-node {
    .close-container {
      a.close-link {
        margin-#{$rdirection}: 26px;
      }
    }
  }
  .profile-password-update {
    &__fieldset {
      margin-#{$ldirection}: -12px;
      @media #{$medium-up} {
        margin-#{$ldirection}: 1px;
      }
    }
  }
  .password-field {
    &__info {
      @media #{$medium-up} {
        width: 21%;
        border: 1px solid $color-black;
        &::before {
          top: 27%;
          #{$rdirection}: 89%;
          -webkit-transform: rotate(90deg);
          -moz-transform: rotate(90deg);
          -o-transform: rotate(90deg);
          transform: rotate(90deg);
        }
        #signin &,
        #billing & {
          width: 25%;
          #{$ldirection}: 254px;
          bottom: -47px;
          &:before {
            #{$ldirection}: -6%;
            top: 51%;
            -webkit-transform: rotate(90deg);
            -moz-transform: rotate(90deg);
            -o-transform: rotate(90deg);
            transform: rotate(90deg);
            #{$rdirection}: 177px;
          }
        }
        #gnav_signin & {
          &::before {
            top: -11%;
            #{$rdirection}: 52%;
          }
        }
        #password_reset & {
          &::before {
            -webkit-transform: rotate(0);
            -moz-transform: rotate(0);
            -o-transform: rotate(0);
            transform: rotate(0);
            top: 16%;
            #{$rdirection}: 100%;
          }
        }
      }
    }
    &__rules {
      column-count: 2;
      @media #{$medium-up} {
        column-count: 1;
      }
    }
  }
}

#change_password {
  .password-field__rules {
    column-count: 2;
  }
}

.device-mobile {
  .block-template-gnav-utility-button-mobile-v1,
  .block-template-gnav-cart-v1 {
    top: 7px;
  }
  /* !important is added as there is a !important in CL base class */
  .navigation {
    .field-mobile-menu {
      .menu {
        li {
          a {
            span.special {
              color: $color-cl-green !important;
            }
          }
        }
      }
    }
  }
  .store-locator-mobile-v1 {
    .map_container {
      width: 100%;
      .map_canvas {
        width: 100%;
      }
      .gm-style-iw {
        @include swap_direction(padding, 0 10px 5px);
      }
    }
  }
  #top {
    #header {
      .site-logo-responsive-v1 {
        a {
          img {
            @media #{$small-only} {
              margin-#{$ldirection}: -45px;
            }
          }
        }
      }
    }
  }
  #footer {
    .footer-bottom {
      text-align: center;
      padding-bottom: 35px;
      .field-mobile-menu {
        ul.menu > li {
          display: inline-block;
          float: none;
          &.last {
            border-#{$ldirection}: 1px solid $color-cl-soft-black;
            padding-#{$ldirection}: 15px;
            .active {
              display: none;
            }
          }
        }
        @media only screen and (width: 375px) and (orientation: portrait) {
          ul.menu > li {
            &.item-2 {
              margin-#{$rdirection}: 4px;
            }
            &.item-4 {
              border-#{$rdirection}: 1px solid $color-cl-soft-black;
              padding-#{$rdirection}: 15px;
            }
            &.item-5 {
              padding-#{$ldirection}: 10px;
            }
            &.item-6 {
              border-#{$ldirection}: none;
            }
          }
        }
        @media only screen and (width: 667px) and (orientation: landscape) {
          ul.menu > li {
            &.last {
              border-#{$ldirection}: none;
            }
            &.item-3 {
              padding-#{$rdirection}: 10px;
            }
            &.item-4 {
              border-#{$ldirection}: 1px solid $color-cl-soft-black;
              padding-#{$ldirection}: 10px;
            }
          }
        }
        @media only screen and (min-width: 414px) and (orientation: portrait) {
          ul.menu > li {
            &.item-3 {
              border-#{$ldirection}: 1px solid $color-cl-soft-black;
              padding-#{$ldirection}: 10px;
            }
            &.item-4 {
              border-#{$ldirection}: none;
              padding-#{$ldirection}: 0;
              padding-#{$rdirection}: 10px;
            }
            &.item-5 {
              border-#{$ldirection}: 1px solid $color-cl-soft-black;
              padding-#{$ldirection}: 10px;
            }
          }
        }
      }
    }
  }
}

.main_container {
  .field-mobile-template {
    .right_logo-link {
      img {
        display: none;
      }
    }
    .store-locator-mobile-v1 {
      .button {
        line-height: 33px;
      }
    }
    .outerwrap {
      li:last-child {
        @include swap_direction(padding, 0);
        border-#{$ldirection}: 0;
        width: 100%;
      }
    }
  }
  .footer-top {
    .footer-trustmark {
      img {
        width: 100px;
      }
    }
  }
  .customer-care-list {
    &-item {
      &.chat,
      &.sms {
        display: none;
      }
    }
  }
  .column-main {
    .field-mobile-template {
      img {
        width: auto;
      }
      .clinical-reality {
        &__intro {
          &--reality-img {
            width: 200px;
          }
          &--banner-img {
            width: 100%;
          }
        }
      }
      .elc-img {
        width: 100%;
      }
    }
    .block {
      .opaque-wrapper {
        height: 100%;
        overflow: hidden;
        position: fixed;
        top: 0;
        width: 100%;
        z-index: 10000;
        background-color: $color-white;
        opacity: 0.7;
      }
      .signup,
      .thankyou {
        width: 100%;
        #{$ldirection}: 0%;
        height: auto;
        h2 {
          text-align: center;
          margin-top: 7%;
          margin-bottom: 4%;
          font-size: 32px;
        }
        .subheading {
          margin-bottom: 15px;
          p {
            text-align: center;
            line-height: 20px;
          }
        }
        .signup-forms {
          .form-submit-wrap {
            @include swap_direction(margin, 0);
            .form-text {
              width: 100%;
            }
            .form-submit {
              @include swap_direction(margin, 10px 0 0 0);
            }
          }
        }
      }
      .signedup {
        h2 {
          margin-top: 70px;
        }
      }
    }
  }
  .gnav-logo {
    float: #{$ldirection};
    width: 45%;
    .field-mobile-template {
      .right_logo-link {
        display: none;
      }
    }
  }
}

.ingredients {
  margin-top: 20px;
  .ingredients_toggle {
    display: inline-block;
    height: 10px;
    width: 10px;
    cursor: pointer;
  }
  .ingredients_disclaimer {
    margin-bottom: 15px;
  }
  .ingredients_less {
    background-image: url('/media/export/cms/images/minus_icon.png');
    margin-bottom: 4px;
  }
  .ingredients_more {
    background-image: url('/media/export/cms_2.0/SPP/ingredient/product_details_open.png');
  }
  .ingredients_content {
    margin-top: 10px;
  }
}

.tracking-link a {
  pointer-events: none;
}

.bv-widgets {
  display: none;
}

.holiday-game-2020 {
  &__game-winscreen-copy {
    &--middle {
      .holiday-game-2020 {
        &__game-winscreen-copy {
          &--percent {
            div {
              position: static;
            }
          }
        }
      }
    }
  }
}

.elc-search-typeahead-count {
  display: none;
}

/* Common Issue */
.inner-block {
  .social-login {
    label {
      font-family: $base-font-family-roman;
    }
    &__terms {
      text-align: #{$ldirection};
      padding: 0;
    }
  }
}
/* Gnav */
.gnav-my-account-v1 {
  @media #{$large-only} {
    overflow-y: auto;
    height: 450px;
  }
  .gnav {
    .field.facebook_email_opt_in {
      margin-top: 10px;
    }
    .facebook-logo {
      display: none;
    }
  }
}
/* Profile Page */
.registration_content {
  .social-info {
    &__content {
      p {
        margin-bottom: 15px;
      }
      .social-info__connect {
        margin-bottom: 20px;
        span {
          @media #{$medium-up} {
            padding-top: 3px;
          }
        }
      }
    }
  }
}
/* account Signin Page */
#signin {
  #signin-block {
    .social-login.gnav {
      .social-login__email-opt-in {
        input.field {
          margin-top: 17px;
        }
      }
    }
    .social-login {
      .social-login__email-opt-in {
        display: flex;
        input[type='checkbox'] {
          @media not all and (min-resolution: 0.001dpcm) {
            @supports (-webkit-appearance: none) {
              padding: 6px;
              width: 15px;
              height: 15px;
            }
          }
          width: auto;
          height: auto;
        }
        input.field {
          margin-top: 3px;
        }
        label {
          font-family: $base-font-family-roman;
          margin-#{$ldirection}: 5px;
          color: $color-darkest-grey;
          @media #{$medium-up} {
            margin-#{$ldirection}: 10px;
          }
        }
        .label-content {
          color: $color-btn-dark-gray;
          font-family: $base-font-family;
        }
      }
      &__terms {
        @media #{$medium-up} {
          text-align: #{$ldirection};
          padding: 0;
        }
        p {
          color: $color-darkest-grey;
        }
      }
    }
  }
  .checkout_signin {
    .social-login {
      margin: 0;
    }
  }
}
/* Confirmation Page */
#confirm,
.checkout-confirm {
  .social-login {
    border-top: 1px solid $color-medium-white;
    margin-top: 30px;
    .checkout__panel-title {
      text-align: #{$ldirection};
      padding: 0;
      display: block;
    }
    &__divider {
      display: none;
    }
    &__info {
      text-align: #{$ldirection};
      margin-bottom: 20px;
      display: block;
    }
    &__email-opt-in {
      text-align: #{$ldirection};
      input[type='checkbox'] {
        @include swap_direction(padding, 6px);
      }
      .label-content {
        color: $color-darkest-grey;
        font-family: $base-font-family-roman;
      }
    }
    &__terms {
      display: none;
    }
  }
  .multi {
    .social-login {
      .social-login__email-opt-in {
        input[type='checkbox'] {
          width: 19px;
          height: 19px;
          @media #{$medium-up} {
            width: auto;
            height: auto;
          }
        }
      }
    }
  }
}

.main_container {
  .accordionItem {
    &.three-step-shopbystep-mobile-v1 {
      h3.product-name {
        max-height: initial;
      }
      .product-price {
        margin-top: 10px;
      }
    }
  }
}

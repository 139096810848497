.main_container {
  .product-list {
    .desc {
      ul {
        margin-bottom: 20px;
        margin-#{$ldirection}: 20px;
        li {
          @include swap_direction(margin, 5px 0);
          list-style-type: disc;
        }
      }
    }
  }
}
